// Import library functions for theme creation.
@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin link-with-icon-theme($theme) {
  $primary: map-get($theme, primary);

  a.mp-link {
    cursor: pointer;
    color: mat.get-color-from-palette($primary);
    display: flex;
    text-decoration: none;
    justify-content: inherit;
    align-items: inherit;
  }
}
