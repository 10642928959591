@use '@angular/material' as mat;
// Plus imports for other components in your app.
@import 'styles/roboto_flex';
@import 'styles/material-design-icons';
@import 'app/shared/charts/chart-pagination/chart-pagination-theme';
@import 'app/shared/account/account-theme';
@import 'app/shared/navigation/navigation-theme';
@import 'app/shared/modal-dialog/modal-dialog-theme';
@import 'app/shared/status-indicator/status-indicator-theme';
@import 'app/shared/placeholder/placeholder-theme';
@import 'app/shared/link/link-with-icon/link-with-icon-theme';
@import 'app/partnerkraft/partnerkraft-theme';
@import 'app/solar/solar-theme';

$font-family: 'Roboto Flex Normal', 'Arial', 'Helvetica Neue', 'sans-serif';

$swm-typography: mat.define-typography-config(
  $font-family: $font-family,
  $body-1: mat.define-typography-level(16px, 20px, 350),
  $body-2: mat.define-typography-level(14px, 20px, 350),
  $subtitle-1: mat.define-typography-level(18px, 30px, 550),
  $subtitle-2: mat.define-typography-level(16px, 28px, 550),
  $headline-5: mat.define-typography-level(24px, 32px, 550),
  $headline-6: mat.define-typography-level(24px, 32px, 550),
  $button: mat.define-typography-level(16px, 28px, 550),
);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.all-component-typographies($swm-typography);
@include mat.core;

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$swm-blue: (
  50: #e5ebf3,
  100: #becce1,
  200: #93abcd,
  300: #6889b8,
  400: #476fa9,
  500: #27569a,
  600: #234f92,
  700: #1d4588,
  800: #173c7e,
  900: #0e2b6c,
  A100: #a0baff,
  A200: #6d95ff,
  A400: #3a70ff,
  A700: #205dff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$swm-light-blue: (
  50: #e0f3fb,
  100: #b3e2f6,
  200: #80cff0,
  300: #4dbbe9,
  400: #26ade5,
  500: #009ee0,
  600: #0096dc,
  700: #008cd8,
  800: #0082d3,
  900: #0070cb,
  A100: #f3f9ff,
  A200: #c0dfff,
  A400: #8dc5ff,
  A700: #74b9ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$swm-red: (
  50: #f3e4e6,
  100: #e0bac2,
  200: #cc8d99,
  300: #b75f70,
  400: #a73c51,
  500: #981a32,
  600: #90172d,
  700: #851326,
  800: #7b0f1f,
  900: #6a0813,
  A100: #ff9ba3,
  A200: #ff6874,
  A400: #ff3545,
  A700: #ff1b2d,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$swm-primary: mat.define-palette($swm-blue);
$swm-accent: mat.define-palette($swm-light-blue);

// The warn palette is optional (defaults to red).
$swm-warn: mat.define-palette($swm-red);

// Create the theme object (a Sass map containing all of the palettes).
$swm-theme: mat.define-light-theme((
  color: (
    primary: $swm-primary,
    accent: $swm-accent,
    warn: $swm-warn,
  ),
  typography: $swm-typography,
  density: 0,
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include chart-pagination-theme($swm-theme);
@include mat.all-component-themes($swm-theme);
@include account-theme($swm-theme);
@include navigation-theme($swm-theme);
@include modal-dialog-theme($swm-theme);
@include placeholder-theme($swm-theme);
@include link-with-icon-theme($swm-theme);

@include status-indicator-theme($swm-theme);
@include partnerkraft-theme($swm-theme);
@include solar-theme($swm-theme);

.app-title {
  @include mat.typography-level($swm-typography, 'headline-5');
}
