// Import library functions for theme creation.
@use '@angular/material' as mat;
@import '_colors.scss';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin status-indicator-theme($theme) {
  // Extract the palettes you need from the theme definition.
  $warn: map-get($theme, warn);

  // Define any styles affected by the theme.
  .app-status-indicator.app-color-success,
  .app-status-indicator.mat-success {
    background-color: $swm-color-secondary-1;
  }

  .app-status-indicator.app-color-warn,
  .app-status-indicator.mat-warn {
    background-color: mat.get-color-from-palette($warn);
  }
}
