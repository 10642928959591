// Import library functions for theme creation.
@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin modal-dialog-theme($theme) {
  // Extract the palettes you need from the theme definition.
  $foreground: map-get($theme, foreground);

  // Define any styles affected by the theme.
  .modal-dialog-form {
    .mat-mdc-dialog-title {
      border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);
    }
    .mat-mdc-dialog-actions {
      border-top: 1px solid mat.get-color-from-palette($foreground, divider);
    }
  }
}
