// Import library functions for theme creation.
@use '@angular/material' as mat;
@import './start/start-theme';
@import './start/status/status-theme';
@import './start/anlagen/filter/filter-theme';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin partnerkraft-theme($theme) {
  @include partnerkraft-start-theme($theme);
  @include partnerkraft-status-theme($theme);
  @include partnerkraft-filter-theme($theme);

  $primary: map-get($theme, primary);

  a.mp-link {
    color: mat.get-color-from-palette($primary);
  }
}
