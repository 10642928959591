// Import library functions for theme creation.
@use '@angular/material' as mat;
@import '_colors.scss';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin partnerkraft-status-theme($theme) {

  $background: map-get($theme, background);
  $warn: map-get($theme, warn);

  .mp-status-wrapper {
    background-color: map-get(mat.$grey-palette, 200);
  }
  .mp-status-display-box {
    background-color: mat.get-color-from-palette($background, background);
  }
  .status-on {
    color: $swm-color-secondary-1;
  }
  .status-off {
    color: mat.get-color-from-palette($warn);
  }

}
