$swm-color-primary: #27569a;
$swm-color-secondary-1: #75ad40;
$swm-color-secondary-2: #000000;

$swm-color-accent-1: #009ee0;
$swm-color-accent-2: #009ab1;
$swm-color-accent-3: #00925b;
$swm-color-accent-4: #be7011;
$swm-color-accent-5: #981a32;
$swm-color-accent-6: #eb6909;
$swm-color-accent-7: #fbba00;

$swm-color-structure-1: #546a75;
$swm-color-structure-2: #738792;
$swm-color-structure-3: #b7c4cb;

$swm-color-notice: #6e6e6e;
$swm-color-label: #4a5056;
$swm-color-seperator: #c6cbd0;
$swm-page-background-white: #ffffff;
$swm-error-background-color: $swm-color-accent-5;

$swm-color-green-active: #3c8225;
