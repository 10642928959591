// Import library functions for theme creation.
@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin energiefluss-details-theme($theme) {
  $primary: map-get($theme, primary);

  text.category-value {
    fill: mat.get-color-from-palette($primary);
  }

  text.category-battery-value {
    fill: mat.get-color-from-palette($primary);
  }

  path.category-icon {
    fill: mat.get-color-from-palette($primary);
  }
}
