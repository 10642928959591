// Import library functions for theme creation.
@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin navigation-theme($theme) {
  // Extract the palettes you need from the theme definition.
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $typography-config: mat.get-typography-config($theme);

  // Define any styles affected by the theme.
  .app-navigation {
    .app-navigation-top {
      nav {
        button.active {
          border-bottom-color: mat.get-color-from-palette($primary, default-contrast);
        }
      }
    }

    .app-navigation-bottom {
      background-color: mat.get-color-from-palette($background, background);
      border-top: 1px solid mat.get-color-from-palette($foreground, divider);

      nav {
        .mat-mdc-icon-button {
          color: mat.get-color-from-palette($foreground, icon);
          background-color: mat.get-color-from-palette($background, background);
          font-size: mat.font-size($typography-config, button);
        }

        .mat-mdc-icon-button:focus,
        .mat-mdc-icon-button:active,
        .mat-mdc-icon-button.active {
          color: mat.get-color-from-palette($primary)
        }
      }
    }
  }
}
