/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import 'variables';
@import 'mixins';
@import 'swm-theme';

.flex-spacer {
  flex-grow: 1;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  overflow-y: scroll;
  overflow-x: hidden;
  overscroll-behavior: none;
  height: 100%;
}

body {
  min-height: 100%;
}

.white-background {
  background-color: $swm-page-background-white;
}

/* Hide HTML5 Up and Down arrows. */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.app-root {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.app-content {
  padding-top: $app-header-height;
  display: flex;
  justify-content: center;
}

.app-console .app-content {
  padding-bottom: $console-window-height;
}

.app-container-without-margins {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.app-container {
  width: auto;
  margin-left: $content-margin-xs;
  margin-right: $content-margin-xs;
}

@media (max-width: $screen-xs-max) {
  .app-container {
    flex-grow: 1;
    width: $content-width-xs;
  }
}

@media (min-width: $screen-sm-min) {
  .app-container {
    width: $content-width-sm;
    margin-left: $content-margin-sm;
    margin-right: $content-margin-sm;
  }
}

@media (min-width: $screen-md-min) {
  .app-container {
    width: $content-width-md;
    margin-left: $content-margin-md;
    margin-right: $content-margin-md;
  }
}

@media (min-width: $screen-lg-min) {
  .app-container {
    width: $content-width-lg;
    margin-left: $content-margin-lg;
    margin-right: $content-margin-lg;
  }
}

.app-title-image {
  text-align: center;
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;
  height: 240px;
  width: 240px;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: local;
  background-size: contain;

  img {
    width: 240px;
  }
}

@media (max-width: $screen-xs-max) {
  .app-title-image {
    margin-bottom: 24px;
    height: 156px;
    width: 156px;

    img {
      width: 156px;
    }
  }
}

.app-body-text,
.app-centered {
  @include centered;
}

h1.app-headline {
  margin-top: 32px;
  @include centered;
}

.app-headline-2 {
  @include mat.typography-level($swm-typography, 'headline-6');
  font-size: 18px;
  line-height: 27px;
}

h2.app-title {
  margin-top: 24px;
}

.app-form {
  margin-top: 32px;
}

.dividers > .app-form-group {
  border-bottom: 1px solid rgba(black, 0.12);
}

.app-form-group {
  display: flex;
  align-items: center;
  min-height: 36px;
  margin-bottom: 4px;
  padding-bottom: 4px;

  label {
    flex-grow: 1;
  }

  span {
    flex-shrink: 0;
    display: inline-block;
  }
}

.app-form-grid {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-row-gap: 12px;
  width: 100%;

  label {
    align-self: center;
  }

  span {
    justify-self: end;
  }
}

.app-form-footer {
  margin-top: 32px;
}

body.fullscreen-active {
  overflow-y: hidden;

  .cdk-overlay-pane {
    max-width: 100% !important;
  }

  .mat-mdc-dialog-container {
    border-radius: 0;
    padding: 0;

    .mat-mdc-dialog-content {
      margin-left: 0;
      margin-right: 0;
      padding-left: 16px;
      padding-right: 16px;
      position: absolute;
      max-height: none;
      top: 56px;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}

.cdk-overlay-pane .mat-mdc-snack-bar-container,
.cdk-overlay-pane.mat-mdc-snack-bar-handset .mat-mdc-snack-bar-container {
  margin-bottom: $app-footer-height + 8px;
}

.app-platform-ios .cdk-overlay-pane .mat-mdc-snack-bar-container,
.app-platform-ios,
.cdk-overlay-pane.mat-mdc-snack-bar-handset,
.mat-mdc-snack-bar-container {
  margin-bottom: $app-footer-height-ios + 8px;
}

@media (min-width: $screen-md-min) {
  .cdk-overlay-pane,
  .cdk-overlay-pane.mat-mdc-snack-bar-handset {
    .mat-mdc-snack-bar-container {
      min-width: $content-width-md;
      max-width: $content-width-md;
      margin-bottom: 8px;

      .mdc-snackbar__surface {
        width: 100%;
        max-width: unset;
      }
    }
  }
  .cdk-overlay-pane.mat-mdc-snack-bar-handset {
    width: auto;
  }
}

.mat-mdc-tab {
  padding: 0 !important;
  opacity: 0.6;
}

.mdc-tab--active {
  opacity: 1;
}


.mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
  background-color: $swm-color-secondary-1;
}

.mat-mdc-input-element:read-only {
  color: grey;
}

mat-label.editable {
  color: black;
}

.swm-full-button {
  width: 100%;
  font-size: 16px;
}

.account-snackbar {
  margin-left: 16px !important;
  margin-right: 16px !important;
  margin-bottom: 76px !important;
}


.mobile-bottom-sheet {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}


.fullscreen-dialog {
  max-width: 100% !important;
  max-height: 100% !important;
  width: 100%;
  height: 100%;

  .mat-mdc-dialog-container {
    border-radius: 0;
  }

  .mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 24px;
  }
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  padding: 24px;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  line-height: inherit !important;
  font-size: inherit !important;
  font-weight: inherit !important;
  letter-spacing: inherit !important;
  padding: 0 !important;
}

.mat-mdc-dialog-container .mdc-dialog__title {
  padding: 0;
}

.mat-mdc-dialog-container .mdc-dialog__title::before {
  display: block;
  height: auto;
}

.desktop-dialog {
  width: 528px;
  height: 560px;

  .mat-toolbar-row h3 {
    align-self: flex-start;
  }
}

.error-snackbar-panel {
  background-color: $swm-error-background-color;
  color: white;
}

.section-content {
  background-color: white;
  padding: 16px 16px;
}

.mat-horizontal-content-container {
  padding: 0 16px 16px 16px !important;
}

a {
  color: $swm-color-primary;
}

a:visited {
  color: #462C68;
}
