// Import library functions for theme creation.
@use '@angular/material' as mat;
@import '_colors.scss';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin partnerkraft-start-theme($theme) {

  .mp-status-background {
    background-color: map-get(mat.$grey-palette, 200);
  }
}
