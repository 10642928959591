// Import library functions for theme creation.
@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin account-theme($theme) {
  // Extract the palettes you need from the theme definition.
  $foreground: map-get($theme, foreground);

  .app-account-item {
    color: mat.get-color-from-palette($foreground, text);

    .app-account-icon {
      color: mat.get-color-from-palette($foreground, icon);
    }
  }
}
