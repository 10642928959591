$screen-xs-min: 360px;
$screen-sm-min: 600px;
$screen-md-min: 840px;
$screen-lg-min: 1280px;

$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);

$content-margin-xs: 16px;
$content-margin-sm: 16px;
$content-margin-md: 24px;
$content-margin-lg: 24px;

$content-width-xs: ($screen-xs-min - 2 * $content-margin-xs);
$content-width-sm: ($screen-sm-min - 2 * $content-margin-sm);
$content-width-md: ($screen-md-min - 2 * $content-margin-md);
$content-width-lg: 1080px;

$app-header-height: 56px;
$app-footer-height: 56px;

$app-footer-height-ios: $app-footer-height + 16px;

$console-window-height: 64px;
