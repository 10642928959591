@mixin content-width {
  left: 0;
  right: 0;
  width: auto;
}

@mixin bold {
  font-weight: 550;
}

@mixin centered {
  max-width: 550px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}


